.landing {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-bottom: 250px;
  gap: 120px;
}

.catalog {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 105px;
}
