.container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}

.back__button {
  position: relative;
  left: 50px;
  cursor: pointer;
  font-size: 32px;
}