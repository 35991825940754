.container {
  display: flex;
  justify-content: center;
}

.back__button {
  position: relative;
  right: 50px;
  cursor: pointer;
  font-size: 32px;
}

.flex {
  display: flex;
}

.md_margin_bottom {
  margin-bottom: 50px;
}

.card {
  display: flex;
  gap: 50px;
  background-color: white;
  padding: 25px;
  min-width: 600px;
  border: 1px solid grey;
}

#move {
  position: relative;
  right: 10px;
}

.image__container {
  height: 520px;
  max-width: 800px;

  img {
    width: 100%;
    height: 100%;
  }
}

.information__panel {
  position: relative;
  top: 50px;
  padding-bottom: 35px;

  .name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .price {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
  }

  .restriction {
    margin-bottom: 25px;
    font-weight: 500;
    color: rgb(225, 0, 0);
  }
}

.selected {
  outline: 4px solid rgb(251, 251, 6);
}

.size__selector {
  margin-bottom: 25px;
}

.color__selector {
  display: flex;
  gap: 15px;
}

.color__option {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color__title {
  font-size: 18px;
  font-weight: 500;
}

.color__block {
  height: 32px;
  width: 38px;
  cursor: pointer;
  border: 1px solid grey;
}

.color__triangle {
  height: 0px;
  width: 0px;
  border-right: 38px solid transparent;
  border-top: 29px solid white;
}

.color__name p {
  width: 60px;
  text-align: center;
}

.selector {
  margin-top: 20px;
  width: 200px;
}

.checkout__container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3c313;
  width: 135px;
  height: 30px;
  margin-top: 15px;
  border-radius: 15px;
  cursor: pointer;

  p {
    font-size: 16px;
    position: relative;
    bottom: 1px;
    font-weight: 500;
  }
}

table {
  margin-top: 20px;
  input {
    width: 100%;
  }
}

td {
  width: 50px;
}

th {
  width: 75px;
}

.Anthracite {
  background-color: #575859;
}

.Battleship_Grey {
  background-color: #444649;
}

.Black {
  background-color: black;
}

.Blacktop {
  background-color: black;
}

.Black_Charcoal_Heather {
  background-color: #77727434;
}

.Black_Heather {
  background-color: rgba(104, 101, 101, 0.986);
}

.Black_Leather {
  background-color: black;
}

.Blue {
  background-color: #09399e;
}

.Blue_Lake {
  background-color: #66baeb;
}

.Blue_Tint {
  background-color: #cefff3;
}

.Bright_Purple {
  background-color: #25022c;
}

.Brilliant_Blue_Hthr {
  background-color: rgb(159, 233, 247);
}

.Burgundy {
  background-color: #6f0f08;
}

.Candy_Pink {
  background-color: #e7a5d3f1;
}

.Carolina_Blue {
  background-color: #55a3e2;
}

.Charcoal {
  background-color: #575859;
}

.Chrome_Faced {
  background-color: #5a5a5b;
}

.Chrome_Plated {
  background-color: #5a5a5b;
}

.Classic_Blue {
  background-color: rgb(44, 163, 199);
}

.Crimson {
  background-color: rgb(131, 17, 17);
}

.Cobalt_Blue {
  background-color: rgb(54, 128, 240);
}

.Cool_Grey {
  background-color: #aeafb1;
}

.Deep_Black {
  background-color: rgb(100, 100, 100);
}

.Deep_Orange {
  background-color: #ce4d1a;
}

.Deep_Red {
  background-color: #b41b1b;
}

.Deep_Smoke {
  background-color: #737777;
}

.Diesel_Grey {
  background-color: rgb(85, 80, 80);
}

.Direct_Blue {
  background-color: #108fb6;
}

.Dress_Blue_Navy {
  background-color: #192b5c;
}

.Electric_Blue {
  background-color: rgb(13, 135, 216);
}

.Engine_Red {
  background-color: rgb(196, 2, 2);
}

.Forest_Green {
  background-color: #025225;
}

.Game_Royal {
  background-color: rgb(10, 14, 219);
}

.Gear_Grey_Hthr {
  background-color: #a7a5a0;
}

.Gold {
  background-color: #c98c09;
}

.Gorge_Green {
  background-color: #025225;
}

.Graphite {
  background-color: #555555f1;
}

.Graphite_Black {
  background-color: #555555f1;
}

.Green {
  background-color: #245c05;
}

.Grey {
  background-color: #8b8c8f;
}

.Grey_Concrete {
  background-color: #9da6b0;
}

.Grey_Steel {
  background-color: #575859;
}

.Gun_Metal {
  background-color: #5a5a5b;
}

.Gym_Blue {
  background-color: #0653cf;
}

.Hunter {
  background-color: #025225;
}

.Iron_Grey {
  background-color: #5f6062;
}

.Kelly_Green {
  background-color: #0d8041;
}

.Khaki {
  background-color: #d8b60b;
}

.Light_Pink {
  background-color: #e9a5d8;
}

.Lime_Shock {
  background-color: #8ab821;
}

.Magnet {
  background-color: rgb(99, 93, 93);
}

.Metal {
  background-color: rgb(151, 151, 151);
}

.Maroon {
  background-color: #421111;
}

.Medium_Blue {
  background-color: #1cc5f0f1;
}

.Medium_Grey {
  background-color: #6c6d74;
}

.Metal_Blue_Hthr {
  background-color: #9fc5f0;
}

.Navy {
  background-color: #021b40;
}

.Navy_Blazer {
  background-color: #472236a9;
}

.Navy_Heather {
  background-color: #4781ce8e;
}

.Neon_Pink {
  background-color: #d84c92f1;
}

.Patriot_Royal {
  background-color: #052e70;
}

.Peacoat {
  background-color: #233450;
}

.Pearl_Grey_Heather {
  background-color: #d8ccd156;
}

.Pink {
  background-color: #f659a5;
}

.Pink_Raspberry {
  background-color: #db0b62;
}

.Purple {
  background-color: #5112a3;
}

.Quiet_Shade_Grey_Hthr {
  background-color: rgb(172, 172, 172);
}

.Red {
  background-color: #eb4034;
}

.Regatta_Blue {
  background-color: #294366;
}

.Rich_Red {
  background-color: #b90808;
}

.Royal {
  background-color: #0653cf;
}

.Salsa {
  background-color: #eb371ff1;
}

.Signal_Red {
  background-color: rgb(211, 24, 24);
}

.Silver {
  background-color: #8e8e90;
}

.Steel_Grey {
  background-color: #474747;
}

.Stormy_Grey {
  background-color: rgb(59, 58, 58);
}
.Texas_Orange {
  background-color: #b94e06;
}

.TNF_Black {
  background-color: black;
}

.TNF_Drk_Gry_Hthr {
  background-color: rgb(141, 136, 136);
}

.TNF_Med_Gry_Hthr {
  background-color: rgba(211, 205, 205, 0.76);
}

.Tour_Blue {
  background-color: #649bec;
}

.Tropic_Blue {
  background-color: #0c7477;
}

.True_Navy {
  background-color: #021b40;
}

.True_Red {
  background-color: #eb4034;
}

.True_Royal {
  background-color: #09399e;
}

.University_Red {
  background-color: #eb4034;
}

.Valor_Blue {
  background-color: #a3bcf3;
}

.Vegas_Gold {
  background-color: #bba466;
}

.Very_Berry {
  background-color: #a52d8b;
}

.Vintage_Indigo {
  background-color: rgb(66, 84, 94);
}

.Woodland_Brown {
  background-color: #b86818;
}

.Woodland_Camo {
  background-color: #3d7c45;
}