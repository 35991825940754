.container {
  display: flex;
  justify-content: center;
  padding: 100px;
}

.back__button {
  position: relative;
  right: 50px;
  cursor: pointer;
  font-size: 32px;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 800px;
  padding: 15px;
  gap: 15px;
  border: 1px solid grey;
}

#move {
  position: relative;
  right: 10px;
}

.tooltip {
  position: absolute;
  right: 0px;
  top: 15px;
}

.text__field {
  width: 225px;
}

.subtotal__container {
  position: relative;
  height: 150px;
  border-top: 1px solid black;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s linear;
}

.bypass__paypal__checkout {
  position: absolute;
  top: 15px;
  right: 75px;
}

.subtotal {
  position: absolute;
  font-size: 18px;
  top: 15px;
  left: 0px;
  font-weight: 500;
}

.checkout__container {
  position: relative;
  left: 350px;
  margin-top: 15px;
  width: 400px;
}