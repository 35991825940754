.footer {
    width: 100%;
    align-self: flex-end;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.container {
    padding: 10px;
}

.name {
    text-transform: uppercase;
    
    margin-bottom: 5px;
}

.address {
    margin: 0;
    font-weight: 500;
}