.landing {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 45px;
  gap: 25px;
  margin-bottom: 150px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 90px;
  max-width: 900px;
  min-height: 500px;
  padding: 15px;
}

.back__button {
  position: absolute;
  top: 120px;
  left: 5%;
  cursor: pointer;
  font-size: 32px;
}

.text__wrapper {
  border: 1px solid grey;
  border-radius: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;

  p {
    font-size: 24px;
    font-weight: bold;
  }
}

@media (max-width: 1650px) {
  .logo__preview {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-left: 20px;

    .logos {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    .title {
      display: flex;
      align-items: center;
    }

    img {
      width: 100px;
    }

    .large__picture {
      width: 145px;
    }

    .quicklane {
      width: 180px;
    }

    p {
      font-weight: bold;
      font-size: 24px;
    }
  }
}

@media (min-width: 1651px) {
  .logo__preview {
    display: flex;
    position: absolute;
    right: 50px;
    top: 150px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-left: 20px;
    width: 350px;

    .logos {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    .title {
      display: flex;
      align-items: center;
    }

    img {
      width: 140px;
    }

    .large__picture {
      width: 165px;
    }

    .quicklane {
      width: 200px;
    }

    p {
      font-weight: bold;
      font-size: 24px;
    }
  }
}